import { gql } from "@apollo/client";

import { CarFragments } from "../Car/CarFragments";
import { GeneralFragments } from "../General/GeneralFragments";

export const FLEET_LISTINGS = gql`
  query FleetListings(
    $before: String
    $after: String
    $searchText: String
    $first: Int
    $where: CarWhereInput
    $orderBy: CarOrderByInput
    $skip: Int
  ) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(
            before: $before
            after: $after
            searchText: $searchText
            first: $first
            where: $where
            orderBy: $orderBy
            skip: $skip
          ) {
            aggregate {
              count
            }
            pageInfo {
              ...CursorInfo
            }
            edges {
              node {
                ...CarListing
                carLookup {
                  wiseMobilitySubscriptionId
                }
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carListing}
  ${GeneralFragments.cursorInfo}
`;

export const CAR_SWITCH_FLEET = gql`
  query CarSwitchFleetOptions($where: CarWhereInput) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(where: $where) {
            edges {
              node {
                ...CarSwitchOption
                location {
                  ...CarLocation
                }
                documents {
                  ...CarDocument
                }
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carSwitchOption}
  ${CarFragments.carDocument}
  ${CarFragments.carLocation}
`;

export const FLEET_LISTINGS_DOCS = gql`
  query FleetListingsDocsOptions($where: CarWhereInput) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(where: $where) {
            edges {
              node {
                ...CarExpirationDatesOptions
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carExpirationDatesOptions}
`;

export const FETCH_ALL_LISTINGS = gql`
  query AllFleetListings {
    viewer {
      id
      me {
        id
        owner {
          id
          cars {
            edges {
              node {
                ...CarListing
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carListing}
`;

export const FLEET_AGGREGATE_QUERY = gql`
  query FleetAggregateQuery($filter: CarWhereInput) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(where: $filter) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
