import React, { createContext, useState } from "react";
import { getContext, compose } from "recompose";
import { PropTypes } from "react-metrics";
import * as get from "lodash/get";

import { client } from "Components/Utils/ApolloProvider";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { IDENTIFY_MUTATION } from "Mutations/User/UserMutations";
import { getHttpReferrer } from "Driver/utils/helpers";
import { UserTypeEnum } from "Enums/StateEnums";
import { analytics } from "./";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const AnalyticsContext = createContext();

export const AnalyticsProvider = compose(
  getContext({
    metrics: PropTypes.metrics
  })
)(({ children }) => {
  const [analyticsFlow, setAnalyticsFlow] = useState("");

  const [
    marketplaceDefaultIndexShown,
    setMarketplaceDefaultIndexShown
  ] = useState();
  const [marketplaceIndexName, setMarketplaceIndexName] = useState();
  const [
    marketplaceMarketplaceAlgoliaQueryId,
    setMarketplaceMarketplaceAlgoliaQueryId
  ] = useState();

  /**
   *  Mobile has names for the sections listed in the
   * `primary_marketplace.marketplace_data.listings_section` object in our
   * Firebase Remote Configuration. The key is "sectionId".
   */
  const mapMarketplaceSectionID = (
    marketplaceConfiguration,
    marketplaceIndexName
  ) => {
    const list = marketplaceConfiguration?.marketplace_data?.listings_sections;
    if (list?.constructor?.name !== "Array") return null;

    const sectionId = list.find(
      item => item.index_name === marketplaceIndexName
    )?.sectionId;
    return sectionId || null;
  };

  const handleLoginMetrics = async isLogin => {
    try {
      const { data } = await client.query({
        query: CURRENT_USER_QUERY,
        fetchPolicy: "network-only"
      });
      const currentUser = get(data, "viewer.me", null);
      const httpReferrer = getHttpReferrer();

      if (currentUser && currentUser.id && currentUser.type) {
        const { id, type, email } = currentUser;
        const traits = {
          orderId: id,
          context: {
            traits: {
              email: email
            },
            referrer: {
              type: "impactRadius",
              id: document.cookie.replace(
                /(?:(?:^|.*;\s*)ImpactClickId\s*=\s*([^;]*).*$)|^.*$/,
                "$1"
              )
            }
          }
        };

        analytics.identify(id, currentUser, {
          context: {
            traits: {
              email: currentUser.email
            }
          }
        });

        if (isLogin)
          client.mutate({
            mutation: IDENTIFY_MUTATION,
            variables: {
              referrer: {
                sessionOrigin: httpReferrer.referrerOrigin,
                sessionParams: httpReferrer.referrerParams
              }
            }
          });

        if (type === UserTypeEnum.driver) {
          analytics.track(
            isLogin
              ? AnalyticsEvents.label.auth.driverSignedIn
              : AnalyticsEvents.label.auth.driverRegistered,
            {
              category: AnalyticsEvents.category.userInteraction,
              action: AnalyticsEvents.action.webConversion,
              label: isLogin
                ? AnalyticsEvents.label.auth.driverSignedIn
                : AnalyticsEvents.label.auth.driverRegistered,
              property: JSON.stringify({
                category: "DRIVER",
                authType: isLogin ? "login" : "register",
                orderId: traits.orderId
              }),
              value: "",
              context: ""
            },
            {
              context: traits.context
            }
          );
        } else {
          analytics.track(
            isLogin
              ? AnalyticsEvents.label.auth.ownerSignedIn
              : AnalyticsEvents.label.auth.ownerRegistered,
            {
              category: AnalyticsEvents.category.userInteraction,
              action: AnalyticsEvents.action.webConversion,
              label: isLogin
                ? AnalyticsEvents.label.auth.ownerSignedIn
                : AnalyticsEvents.label.auth.ownerRegistered,
              property: JSON.stringify({
                category: "OWNER",
                authType: isLogin ? "login" : "register",
                orderId: traits.orderId
              }),
              value: "",
              context: {
                user: currentUser
              }
            },
            {
              context: traits.context
            }
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        analyticsFlow,
        handleLoginMetrics,
        mapMarketplaceSectionID,
        marketplaceDefaultIndexShown,
        marketplaceIndexName,
        marketplaceMarketplaceAlgoliaQueryId,
        setAnalyticsFlow,
        setMarketplaceDefaultIndexShown,
        setMarketplaceIndexName,
        setMarketplaceMarketplaceAlgoliaQueryId
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
});
